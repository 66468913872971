
import axios from "../axios-config";

//app首页banner图
/**
 * 新增
 */
export function BannerConfigController_post(params: any) {
  return axios.post("/api/back/system/BannerConfigController", { ...params });
}

/**
 * 修改
 */
export function BannerConfigController_put(params: any) {
  return axios.put("/api/back/system/BannerConfigController", { ...params });
}

/**
 * 删除
 */
export function BannerConfigController_del(params: any) {
  return axios.delete("/api/back/system/BannerConfigController", {
    params: {
      ...params
    }
  });
}
/**
 *  分页查询
 */
export function BannerConfigController_page(params: any) {
  return axios.get("/api/back/system/BannerConfigController/page", {
    params: {
      ...params
    }
  });
}
/**
 * 查询所有的banner
 */
export function BannerConfigController_find(params: any) {
  return axios.get("/api/back/system/BannerConfigController/find", {
    params: {
      ...params
    }
  });
}