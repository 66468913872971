<template>
  <!-- 用法 一个 <upload v-if="visible" ref="upload" @handleChange="handleChangeUpload" :FormData="form" :listType="'picture-card'" :typeNumber="2" :fileNumber="1"></upload> -->

  <!--  用法 多个  <upload
            v-if="visible"
            v-model:attachFiles="formState.attachFiles"
            :FormData="formState"
            :listType="'picture-card'"
            :typeNumber="3"
            :fileNumber="6"
          ></upload> -->
  <div>
    <a-upload name="file" :list-type="listType" :multiple="true" :headers="headers" :action="action" :before-upload="beforeUpload" :file-list="fileList" @change="handleChange" @preview="(fileNumber>1) &&(typeNumber === 3)&& handlePreview($event)" :disabled="disabled">
      <div v-if="fileList.length < fileNumber && listType == 'picture-card'">
        <plus-outlined />
        <div class="ant-upload-text">上传图片</div>
      </div>
      <a-button v-if="fileList.length < fileNumber && listType !== 'picture-card'">
        上传附件
      </a-button>
    </a-upload>
    <a-modal :visible="previewVisible" footer @cancel="handleCancel">
      <img alt="example" style="width: 100%;padding: 20px;" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
export default {
  components: {
    PlusOutlined
  },
  props: {
    //上传类型
    listType: {
      type: String,
      default: ''
    },
    //
    FormData: {
      type: String,
      required: false
    },
    //校验类型
    typeNumber: {
      type: Number,
      default: 2
    },
    //文件数量
    fileNumber: {
      type: Number,
      default: 1
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      action: '',
      api: process.env.VUE_APP_API_BASE_URL,
      fileList: [],
      headers: {
        Authorization: cookies.get('TOKEN_ACCOUNT')
          ? 'Bearer ' + cookies.get('TOKEN_ACCOUNT')
          : null
      }
    }
  },
  mounted() {
    this.init()
    if (this.typeNumber === 1) {
      this.action = this.api + '/storage/upload'
    } else if (this.typeNumber === 3) {
      this.action = this.api + '/storage/upload/image'
    } else if (this.typeNumber === 4) {
      this.action = this.api + '/storage/upload/video'
    } else {
      this.action = this.api + '/storage/upload'
    }
  },
  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (file.url) {
        this.previewImage = file.url
      } else {
        this.previewImage = file.response?.data.fullPath
      }
      this.previewVisible = true
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        //pdf =>1
        if (this.typeNumber === 1) {
          const isJpgOrPng = file.type === 'application/pdf'
          if (!isJpgOrPng) {
            message.error('请上传PDF格式')
            return reject(false)
          }
          const isLt2M = file.size / 1024 / 1024 < 5
          if (!isLt2M) {
            message.error('上传的文件大小超过5M')
            return reject(false)
          }
          return resolve(true)
        }
        //不限制=>2
        else if (this.typeNumber === 2) {
          return resolve(true)
        }
        //JPG、PNG =>3
        else if (this.typeNumber === 3) {
          const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png'
          if (!isJpgOrPng) {
            message.error('请上传JPG、PNG格式')
            return reject(false)
          }
          const isLt2M = file.size / 1024 / 1024 < 5
          if (!isLt2M) {
            message.error('上传的文件大小不超过5M')
            return reject(false)
          }
          return resolve(true)
        }
        //mp4 =>4
        else if (this.typeNumber === 4) {
          const isJpgOrPng = file.type === 'video/mp4'
          if (!isJpgOrPng) {
            message.error('请上传mp4格式')
            return reject(false)
          }
          const isLt2M = file.size / 1024 / 1024 < 100
          if (!isLt2M) {
            message.error('上传的文件大小超过100M')
            return reject(false)
          }
          return resolve(true)
        }
      })
    },
    handleChange({ file, fileList }) {
      this.fileList = fileList
      this.$emit('handleChange', file)
      this.$emit('update:attachFiles', this.fileList)
    },
    init() {
      this.fileList = []
      if (this.FormData) {
        if (this.fileNumber == 1) {
          if (this.FormData.path) {
            this.fileList.push({
              url: this.FormData.path,
              name: this.FormData.fileName
            })
          }
          if (this.FormData.pic) {
            //banner图
            this.fileList.push({
              url: this.FormData.pic
            })
          }
        } else {
          this.FormData.attachFiles.map((res) => {
            this.fileList.push({
              url: res.path,
              name: res.fileName
            })
          })
          console.log(this.fileList)
        }
      }
    }
  }
}
</script>
