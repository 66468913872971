
import { defineComponent, reactive, toRefs, ref } from "vue";
import pagaTable from "@/components/pagination-table/index";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import {
  BannerConfigController_page,
  BannerConfigController_find,
  BannerConfigController_del,
} from "@/api/homePageBanner";
const purchaseColumns = [
  {
    title: "缩略图 ",
    dataIndex: "pic",
    slots: { customRender: "pic" },
  },
  {
    title: "排序",
    dataIndex: "sort",
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    popupWindow,
    formSearch,
  },
  setup(props: any) {
    const data = reactive({
      imgList: [],
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
    });
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys;
    };
    const Api = () => {
      BannerConfigController_find({ sort: "sort:asc" }).then((res) => {
        data.imgList = res.data;
      });
    };
    const table = ref();
    const getDataApi = (params: any) => {
      Api();
      return new Promise((reslove) => {
        BannerConfigController_page({ sort: "sort:asc" }).then((res: any) => {
          reslove(res);
        });
      });
    };
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      // console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑banner图";
      popupWindow.value.showModal(row);
    };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const handleAdd = () => {
      Title.value = "新增banner图片";
      popupWindow.value.showModal();
    };
    const refresh = () => {
      Api();
      table.value.refresh();
      data.selectedRowKeys = [];
    };
    const del = (val: any) => {
      BannerConfigController_del({ id: val.id }).then((res: any) => {
        refresh();
      });
    };

    return {
      del,
      refresh,
      ...toRefs(data),
      table,
      onSelectChange,
      Api,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleAdd,
    };
  },
});
