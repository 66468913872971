<template>
  <div>
    <a-modal width="600px" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" @ok="handleOk" @cancel="cancel" :confirm-loading="confirmLoading">
      <a-form ref="formRef" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="formState" :rules="rules">
        <a-form-item label="排序：" name="sort">
          <a-input-number v-model:value="formState.sort" :min="0" />
        </a-form-item>
        <a-form-item prop="pic" label="上传图片">
          <upload v-if="visible" ref="upload" @handleChange="handleChangeUpload" :FormData="formState" :listType="'picture-card'" :typeNumber="3" :fileNumber="1"></upload>
        </a-form-item>
        <a-form-item label="跳转链接地址：">
          <a-input v-model:value="formState.url" style="width: 370px" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import upload from '@/components/upload'
import {
  BannerConfigController_post,
  BannerConfigController_put
} from '@/api/homePageBanner'
const form = {
  sort: null,
  delFlag: 0,
  url: '',
  pic: '',
  type: 0
}
export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      formState: {
        ...form
      },
      rules: {
        pic: [{ required: true, message: '不能为空', trigger: 'change' }],
        sort: [
          {
            required: true,
            type: 'number',
            message: '不能为空',
            trigger: 'change'
          }
        ]
      },
      editor: false
    }
  },
  components: {
    upload
  },
  created() {},
  methods: {
    //上传文件回调
    handleChangeUpload(info) {
      this.formState.pic = info.response?.data.fullPath
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.formState = { ...form }
      this.editor = false
      this.visible = false
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true
        if (!this.editor) {
          BannerConfigController_post(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            this.$parent.refresh()
          })
        } else {
          BannerConfigController_put(this.formState).then((res) => {
            this.visible = false
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.cancel()
            this.$parent.refresh()
          })
        }
      })
    },
    showModal(val) {
      this.visible = true
      if (val) {
        this.editor = true
        this.formState = { ...form, ...val }
      } else {
        this.editor = false
      }
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
